import {
  ButtonPrimaryLink,
  CenteredContentWrapper,
  ConfettiBigSectionWrapper,
  device,
  EmphasizedText,
  SectionHeader,
  spacing,
  Subheading,
  TextBody,
  TextToutFullwidth,
  ThumbnailHeader,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
} from "@xolvio/xolvio-ui";
import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import { pageData } from "../../pages-content/services/rapid-backend-development";
import { LayoutDefault } from "../../components/LayoutDefault";
import { TwoColumnSectionHeader } from "../../components/Services/ServicePageTemplate";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { AlternateBackground, ToutRow } from "../../components/Homepage";
import { OtherServices } from "../../components/Services/OtherServices";
import { contactUsFooterProps } from "../../pages-content/homepage";
import { MoveHack } from "../../components/Services";

const Page = ({ data }) => (
  <div>
    <MoveHack topMobile={"-50px"} />
    <ConfettiBigSectionWrapper
      hideMobileConfetti
      style={{
        backgroundColor: "#ffffff",
        marginBottom: "30px",
        zIndex: 100,
      }}
    >
      <HeaderContentWrapper>
        {pageData.header.icon}
        <Subheader>{pageData.header.subheading}</Subheader>
        <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
          {pageData.header.heading}
        </Header>
        <HeaderBody>{pageData.header.description}</HeaderBody>
      </HeaderContentWrapper>
    </ConfettiBigSectionWrapper>

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <StyledImg
          src={"/assets/images/end-to-end-delivery.svg"}
          width="500px"
        />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>{pageData.firstSection.subheading}</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={pageData.firstSection.headerHighlights}
              text={pageData.firstSection.header}
            />
          </TwoColumnSectionHeader>
          <Text>{pageData.firstSection.content}</Text>
        </TwoColumnContentNodeWrapper>
      }
    />

    <MoveHack topMobile={"100px"} />

    <AlternateBackground>
      <CenteredContentWrapper id="howWeWork">
        <ContentWrapper>
          <Subheading style={{ textAlign: "center" }}>HOW WE WORK</Subheading>
          <SectionHeader>The Quality Faster Methodology</SectionHeader>
          <TextBody style={{ margin: "auto", marginBottom: "30px" }}>
            Refined and perfected over more than a decade now, the Quality
            Faster methodology innovates on custom software development by
            combining collaborative requirements facilitation, software quality
            best practices, and malleable architecture expertise.
          </TextBody>

          <ToutRow>
            <div>
              <ThumbnailHeader style={{ margin: "auto" }}>
                Visual requirements facilitation workshops
              </ThumbnailHeader>
              <TextBody style={{ margin: "auto", textAlign: "left" }}>
                At Xolvio, we believe that requirements gathering is the most
                difficult aspect of software delivery. This is why we lead
                projects with accelerated, collaborative requirements
                facilitation techniques that bring stakeholder knowledge into
                the visual realm to inform software design.
              </TextBody>
            </div>
            <div>
              <ThumbnailHeader style={{ margin: "auto" }}>
                Precision & accuracy via time-tested best practices
              </ThumbnailHeader>
              <TextBody style={{ margin: "auto", textAlign: "left" }}>
                We have mastered and take cue from the industry-leading best
                practices for high software quality: Domain-Driven Design,
                Behavior-Driven Development, and Test-Driven Development. This
                allows us to tame complexity and deliver swiftly without
                compromising quality.
              </TextBody>
            </div>
            <div>
              <ThumbnailHeader style={{ margin: "auto" }}>
                Future-proof, malleable architecture
              </ThumbnailHeader>
              <TextBody style={{ margin: "auto", textAlign: "left" }}>
                Leveraging clean, hexagonal, and event-driven architecture
                patterns with GraphQL APIs on top, we deliver systems that can
                scale infinitely, boast superior performance, enable
                unparalleled business intelligence, and that are built for
                integration and CX flexibility—for years to come.
              </TextBody>
            </div>
          </ToutRow>
        </ContentWrapper>
      </CenteredContentWrapper>
    </AlternateBackground>

    <MoveHack top={"150px"} topMobile={"100px"} />

    <TwoColumnsWithImage
      style={{ position: "relative" }}
      imagePosition={"left"}
      height={"600px"}
      imageHeight={"600px"}
      imageNode={
        <StyledImgQualityFaster>
          <img
            src={"/assets/images/quality-faster-general.svg"}
            alt="Quality Faster model"
          />
        </StyledImgQualityFaster>
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>
            {pageData.defaultTextToutFullWidthForServicesProps.subheading}
          </Subheading>
          <TwoColumnSectionHeader>
            {pageData.defaultTextToutFullWidthForServicesProps.heading}
          </TwoColumnSectionHeader>
          <MoveHack top={"25px"} />
          <TextBody>
            {pageData.defaultTextToutFullWidthForServicesProps.content}
          </TextBody>
          <MoveHack top={"25px"} />
          <ButtonPrimaryLink
            href={pageData.defaultTextToutFullWidthForServicesProps.buttonUrl}
            as={"a"}
          >
            {pageData.defaultTextToutFullWidthForServicesProps.buttonText}
          </ButtonPrimaryLink>
        </TwoColumnContentNodeWrapper>
      }
    />

    <MoveHack top={"150px"} topMobile={0} />

    <OtherServices
      services={[
        "Apollo GraphQL Consulting",
        "Experience Graph Managed Services",
        "GraphQL Security Review & Remediation",
        "SDLC & Architecture Consulting",
        "Legacy Systems Modernization",
      ]}
    />

    <TextToutFullwidth {...contactUsFooterProps} />
  </div>
);

export const BespokeMartechSolutions = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <LayoutDefault>
        <Page data={data} />
      </LayoutDefault>
    )}
  />
);

export default BespokeMartechSolutions;

const query = graphql`
  query {
    yachts: file(relativePath: { eq: "sailing_yachts.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonial1: file(
      relativePath: { eq: "people_talking_by_whiteboard.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    manWork: file(relativePath: { eq: "man-computer.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export const HeaderContentWrapper = styled.div`
  flex: 1;
  max-width: 1000px;
  text-align: center;
  justify-content: center;
  margin-bottom: 25px;

  > * {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: auto;
    width: 100%;
  }
  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 65px;
  }
`;

export const Subheader = styled(Subheading)`
  margin-top: ${spacing.mobile.padding.default}px;
`;

export const Header = styled(SectionHeader)`
  max-width: calc(100% - 150px);
  @media ${device.tablet} {
    text-align: center;
    max-width: 750px;
  }
`;

export const HeaderBody = styled(TextBody)`
  max-width: 750px !important;
  text-align: center !important;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

export const StyledImg = styled.img`
  max-width: 500px;
  width: 100%;
`;

export const StyledImgQualityFaster = styled.div`
  margin: 0 auto 0 -6%;
  max-height: 90vh;
  width: 100%;
  text-align: right;

  img {
    max-height: 700px;
  }

  @media ${device.mobile} {
    margin: 40px auto;
  }
`;
