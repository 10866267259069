import { StopwatchIcon } from "@xolvio/xolvio-ui";
import React from "react";

export const pageData = {
  header: {
    icon: <StopwatchIcon height="100px" width="100px" />,
    subheading: "SERVICES / ACCELERATED SOFTWARE TRANSFORMATION",
    heading: "Rapid Backend Development",
    description:
      "Get GraphQL-native backends into production 400% faster than traditional agile teams.",
  },
  firstSection: {
    subheading: "WHAT WE DO",
    header: "Leverage rapid, scalable delivery",
    headerHighlights: "scalable",
    content:
      "Our Quality Faster methodology allows us to build complex, high-quality, GraphQL-native backend quicker than any other vendor. You can rely on us to deliver complex systems that are built to scale, easy to maintain, and meant to last decades.",
  },
  defaultWhatWeDoTout: {
    subheading: "HOW WE DO IT",
    sectionHeader:
      "Leverage our Quality Faster capability that goes beyond custom software development",
    toutGroup1: [
      {
        title: "Collaborative approach",
        body: "Having established a shared understanding of the project, we collaboratively gather specifications from you and your team, implement them quickly, and provide high feedback throughout the process.",
      },
      {
        title: "Outcome focus",
        body: "We allow you to focus on outcomes, while we focus on the smooth and continuous delivery of valuable software. You don’t need to be concerned about anything other than the results of our work.",
      },
      {
        title: "Iterative delivery",
        body: "Get full control of your product development lifecycle. We deliver continuously and incrementally, making sure we’re steering towards your goals while having the flexibility to swiftly adapt when needed.",
      },
    ],
  },
  defaultTextToutFullWidthForServicesProps: {
    subheading: "HOW WE WORK",
    heading: "Iterate to scale with the delivery flywheel",
    content:
      "As an SDLC practice, the Quality Faster methodology encompasses all stages of delivery, allowing for continuous iterations on valuable, scalable, high-quality software.",
    buttonText: "Find out more",
    buttonUrl: "/how-we-work",
  },
};
