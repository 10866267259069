import React from "react";
import {
  SquareIconWithNumber,
  SystemsModernizationIcon,
} from "@xolvio/xolvio-ui";

export const pageData = {
  header: {
    subheading: "SERVICES / ACCELERATED SOFTWARE TRANSFORMATION",
    heading: "Legacy Systems Modernization",
    headingHighlights: "---",
    description: "Migrate your legacy systems to state-of-the-art solutions.",
    icon: <SystemsModernizationIcon height="100px" width="100px" />,
    url: "/services/systems-modernization",
    sectionId: "test",
  },

  firstSection: {
    subheading: "WHAT WE DO",
    header: "Legacy systems suck.",
    headerHighlights: ["Legacy"],
    listItemBold:
      "They’re holding you and your business back. They’re unruly and everyone’s scared to touch them.",
    listItemRegular:
      "Or maybe you try to build upon them but it takes forever, and when you finally manage to do it, you just keep getting bugs. It doesn’t have to be like this.",
  },

  secondSection: {
    subheading: "WHAT WE DO",
    header: "Regain control of your software delivery",
    headerHighlights: "Regain control",
  },
  thirdSection: {
    subheading: "HOW WE DO IT",
    header: "Careful assessment and modular makeover",
    headerHighlights: ["Careful", "makeover"],
    content:
      "We specialize in helping companies rapidly build complex, high-quality software that lasts decades. As modernization experts, we employ a bullet-proof method for legacy migrations, so as to ensure both delivery predictability and perfectly aligned outcomes.",
    image: "/assets/images/systems_modernization.svg",
  },
  toutGroupSet1: {
    iconTout1: {
      // title: "Detailed analysis",
      icon: <SquareIconWithNumber numberToShow={1} data-testid="second-icon" />,
      content:
        "The work starts with mapping out your business processes handled by the legacy system together with a thorough assessment of its current state. This enables us to understand the problems that need to be addressed, the business domains involved, as well as which parts of the system can be kept intact. ",
      url: "url",
      buttonText: "",
    },
    iconTout2: {
      // title: "Rigorous framework",
      icon: <SquareIconWithNumber numberToShow={2} data-testid="second-icon" />,
      content:
        "Understanding your business processes allows us to set up tests and automation that ensure the new software continuously meets all requirements, resulting in a smooth switchover.",
      url: "url",
      buttonText: "",
    },
  },
  toutGroupSet2: {
    iconTout1: {
      // title: "Modular approach",
      icon: <SquareIconWithNumber numberToShow={3} data-testid="second-icon" />,
      content:
        "Meanwhile, modularity facilitates incremental delivery, as well as defining the optimal architecture for future flexibility and scalability.",
      url: "url",
      buttonText: "",
    },
    iconTout2: {
      // title: "Delivery back on track",
      icon: <SquareIconWithNumber numberToShow={4} data-testid="second-icon" />,
      content:
        "Thanks to our stellar expertise in React, AWS, serverless technology, Domain-Driven Design, and clean architecture patterns, your modernized system is going to be a state-of-the-art solution, built to last and to scale infinitely.",
      url: "url",
      buttonText: "",
    },
  },
};
