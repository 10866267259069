import React from "react";
import { LayoutDefault } from "../../components/LayoutDefault";
import { pageData } from "../../pages-content/services/systems-modernization";
import { graphql, StaticQuery } from "gatsby";
import {
  ButtonInlineLink,
  ButtonPrimaryLink,
  CenteredContentWrapper,
  ConfettiBigSectionWrapper,
  device,
  EmphasizedText,
  Hero,
  SectionHeader,
  Spacing,
  spacing,
  Subheading,
  TextBody,
  TextToutFullwidth,
  ToutGroup,
  TwoColumnContentNodeWrapper,
  TwoColumnsWithImage,
  TwoColumnsWrapper,
} from "@xolvio/xolvio-ui";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { OtherServices } from "../../components/Services/OtherServices";
import { contactUsFooterProps } from "../../pages-content/homepage";
import styled from "styled-components";
import { MoveHack } from "../../components/Services";
import { StyledImg } from "./rapid-backend-development";
import {
  HeroContent,
  InlineHeroWithTwoColumnsHeader,
} from "../../components/Services/ServicePageTemplate";

export const SystemsModernization = () => (
  <StaticQuery
    query={query}
    render={(data) => (
      <LayoutDefault>
        <ServicePageTemplate pageData={pageData} data={data} />
      </LayoutDefault>
    )}
  />
);

const query = graphql`
  query {
    firstSectionImage: file(
      relativePath: { eq: "apollo-federation-testing.png" }
    ) {
      childImageSharp {
        fluid(quality: 91, maxWidth: 1700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    secondSectionImage: file(
      relativePath: { eq: "two_bearded_men_pointing_at_laptop.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    yachts: file(relativePath: { eq: "systems_modernization.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default SystemsModernization;

const ServicePageTemplate = ({ pageData, data, additionalNodeAtTheBottom }) => (
  <Wrapper>
    <MoveHack topMobile={"-50px"} />
    <ConfettiBigSectionWrapper
      hideMobileConfetti
      style={{
        backgroundColor: "#ffffff",
        height: "220px",
        marginBottom: "30px",
        zIndex: 100,
      }}
    >
      <HeaderContentWrapper>
        {pageData.header.icon}
        <Subheader>{pageData.header.subheading}</Subheader>
        <Header style={{ maxWidth: "100% !important", textAlign: "center" }}>
          {pageData.header.heading}
        </Header>
        <HeaderBody>{pageData.header.description}</HeaderBody>
      </HeaderContentWrapper>
    </ConfettiBigSectionWrapper>

    <MoveHack top={"80px"} topMobile={"100px"} />

    <Hero
      inline
      style={{ height: "260px" }}
      heading={
        <InlineHeroWithTwoColumnsHeader>
          <EmphasizedText
            text={pageData.firstSection.header}
            highlight={pageData.firstSection.headerHighlights}
          />
        </InlineHeroWithTwoColumnsHeader>
      }
      children={
        <HeroContentWrapper>
          <div />
          <HeroContent>
            <TextBody bold>{pageData.firstSection.listItemBold}</TextBody>
            <TextBody>{pageData.firstSection.listItemRegular}</TextBody>
          </HeroContent>
        </HeroContentWrapper>
      }
    />

    <MoveHack topMobile="50px" topPhone="220px" />

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={<StyledImg src={pageData.thirdSection.image} width="500px" />}
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>{pageData.secondSection.subheading}</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={pageData.secondSection.headerHighlights}
              text={pageData.secondSection.header}
            />
          </TwoColumnSectionHeader>
          <Text>
            At Xolvio, we’ve perfected the art of refactoring even the most
            complex monolithic applications. Thanks to our deep expertise,
            modular approach, and the{" "}
            <ButtonInlineLink
              href={"/how-we-work/"}
              style={{ padding: "0" }}
              as={"a"}
            >
              Quality Faster methodology
            </ButtonInlineLink>{" "}
            you can safely continue business operations during the process. The
            end result? A future-proof solution that you can build upon with new
            features, surely and freely.
          </Text>
        </TwoColumnContentNodeWrapper>
      }
    />

    <MoveHack topMobile={"100px"} />

    <CenteredContentWrapper id="howWeDoIt">
      <ContentWrapper>
        <Subheading style={{ textAlign: "center" }}>
          {pageData.thirdSection.subheading}
        </Subheading>
        <SectionHeader>{pageData.thirdSection.header}</SectionHeader>
        <TextBodyWithMobileLeftAlign
          style={{ maxWidth: "100%", fontSize: "20px" }}
        >
          {pageData.thirdSection.content}
        </TextBodyWithMobileLeftAlign>
      </ContentWrapper>
    </CenteredContentWrapper>

    <ToutGroup {...pageData.toutGroupSet1} />

    <ToutGroup {...pageData.toutGroupSet2} />

    <MoveHack top={"-50px"} topMobile={"100px"} />

    <TwoColumnsWithImage
      imagePosition={"right"}
      imageNode={
        <StyledImg src={"/assets/images/logos/studykik.png"} width="500px" />
      }
      contentNode={
        <TwoColumnContentNodeWrapper>
          <Subheading>MODERNIZATION CASE STUDY</Subheading>
          <TwoColumnSectionHeader>
            <EmphasizedText
              highlight={"transformation"}
              text={"Digital experience transformation"}
            />
          </TwoColumnSectionHeader>
          <Text>
            In just three months, we refactored StudyKIK’s complex monolith into
            an event-driven, distributed architecture with a federated GraphQL
            API on top.
          </Text>
          <MoveHack topMobile={"50px"} />
          <ButtonPrimaryLink
            as={"a"}
            href={"/case-studies/studykik-case-study/"}
          >
            Find out more
          </ButtonPrimaryLink>
          <MoveHack topMobile={"50px"} />
        </TwoColumnContentNodeWrapper>
      }
    />

    <MoveHack top={"-50px"} topMobile={"150px"} />

    <OtherServices
      services={[
        "Apollo GraphQL Consulting",
        "Experience Graph Managed Services",
        "GraphQL Security Review & Remediation",
        "SDLC & Architecture Consulting",
        "Rapid Backend Development",
      ]}
    />

    <TextToutFullwidth {...contactUsFooterProps} />
  </Wrapper>
);

const TextBodyWithMobileLeftAlign = styled(TextBody)`
  @media ${device.mobile} {
    text-align: left;
  }
`;

const Wrapper = styled.div``;

const HeaderContentWrapper = styled.div`
  flex: 1;
  max-width: 1000px;
  text-align: center;
  justify-content: center;
  margin-bottom: 25px;

  > * {
    text-align: center;
    justify-content: center;
    display: flex;
    margin: auto;
    width: 100%;
  }
  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 65px;
  }
`;

const HeaderBody = styled(TextBody)`
  max-width: 750px !important;
  text-align: center !important;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
`;

const Subheader = styled(Subheading)`
  margin-top: ${spacing.mobile.padding.default}px;
`;

const Header = styled(SectionHeader)`
  max-width: calc(100% - 150px);
  @media ${device.tablet} {
    text-align: center;
    max-width: 750px;
  }
`;

export const SpacingBigger = styled(Spacing)`
  height: ${spacing.mobile.betweenSections}px;
  width: 100%;

  @media ${device.tablet} {
    height: ${(props) =>
      spacing.desktop.betweenSections + (props.extraSpace || 60)}px;
  }
`;

export const HeroContentWrapper = styled(TwoColumnsWrapper)`
  @media ${device.mobile} {
    padding: 0 !important;
  }

  > * {
    width: 100%;
  }

  @media ${device.tabletVertical} {
    height: 350px;
    margin-bottom: -160px;
  }
  @media ${device.tablet} {
    > * {
      width: 50%;
    }
  }
`;

export const TwoColumnSectionHeader = styled(SectionHeader)`
  @media ${device.tabletVertical} {
    margin: 20px 0 0;
  }
`;

const Text = styled(TextBody)`
  margin: 16px 0 24px;
`;

export const SpacingSmall = styled.div`
  height: 100px;
`;

const StyledButtonPrimaryLink = styled.div`
  margin: 26px auto 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
